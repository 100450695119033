@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@/style/vars.less";
@import "@/style/fonts.less";
@import "@/style/animations.less";

html {
    background-color: var(--nocp-bg);
}

body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    * {
        font-family: FiraCode, NotoColorEmoji, sans-serif, Arial;
        &::selection {
            background-color: var(--nocp-green);
        }
    }
}

h1, h2, h3, h4, h5, h6, p, strong, span, li, table {
    color: var(--nocp-forebg);
}

h1 {
    font-size: 2em;
}
h2 {
    font-size: 1.5em;
}
h3 {
    font-size: 1.3em;
}
h4 {
    font-size: 1.2em;
}
h5 {
    font-size: 1em;
}
h6 {
    color: var(--nocp-light-gray);
    font-size: 1em;
}

code {
    padding: 3px 5px;
    background-color: #333131;
    border-radius: 3px;
    font-size: 11pt;
    font-family: FiraCode, NotoColorEmoji, sans-serif, monospace, Arial;
    word-wrap: normal;
    white-space: break-spaces;
}

table {
    width: 100%;
    thead {
        border-bottom: 3px solid var(--nocp-gray);
    }
    tr > td {
        vertical-align: text-top;
        white-space: nowrap;
    }
    tr, th, tr > td {
        border-bottom: 1px solid var(--nocp-gray);
    }
    th, tr > td {
        padding: 6px 13px;
    }
    tr:nth-child(2n) {
        background-color: #222;
    }

    @media not all and (min-width: 640px) {
        td {
            padding: 4px 9px;
        }
    }
}

svg {
    transition: all ease .1s;
    fill: var(--nocp-light-gray);
    &:hover {
        fill: var(--nocp-gray);
    }
}

.card-excerpt {
    font-size: 11pt;
    text-align: left;
    &, * {
        color: var(--nocp-light-gray);
        line-height: 1.75rem;
        white-space: normal;
    }
    a {
        text-decoration: underline;
    }
}

// If we use tailwind to set the font color,
// it won't have effect because of the priority
// between different CSS's
.article-card-tag-list {
    a span {
        color: var(--nocp-light-gray);
    }
}

// Gitalk
#gitalk-container {
    text-align: left;
    margin: 0 350px;
    span {
        color: #ccc;
    }
    .gt-meta {
        border-bottom: 1px solid #686868;
    }
    .gt-header .gt-header-comment {
        textarea {
            background-color: #4c4d53;
            caret-color: #ccc;
            color: #fff;
            &::placeholder {
                color: #858585;
            }
        }
        .gt-btn {
            .gt-btn-text {
                color: #fff;
            }
            &.gt-btn-preview {
                background-color: #4c4d53;
                border-color: #4c4d53;
            }
        }
        .gt-header-preview {
            background-color: #4c4d53;
            * {
                color: #fff;
            }
        }
    }
    .gt-comments {
        .gt-comment-content {
            background-color: #4c4d53;
            &:hover {
                box-shadow: none;
            }
        }
        .gt-comment.gt-comment-admin .gt-comment-content {
            background-color: #4d5168;
        }
    }
    .gt-svg svg {
        fill: #fff;
    }
    @media not all and (min-width: 768px) {
        margin: 0 5vw;
    }
}

// KaTeX
.katex * {
    font-family: KaTex_Main;
}

// NProgress
#nprogress {
    .bar {
        height: 3px;
    }
    .peg {
        box-shadow: none !important;
    }
    .bar, .peg {
        background-color: var(--nocp-green) !important;
    }
}

// Medium Zoom
.medium-zoom-image--opened {
    z-index: 1;
}
.medium-zoom-overlay {
    z-index: -1;
}
.medium-zoom--opened .medium-zoom-overlay {
    z-index: 0 !important;
}
