// @font-face {
//     font-family: FiraCode-Bold;
//     src: url("../static/fonts/FiraCode-Bold.ttf");
// }

// @font-face {
//     font-family: FiraCode-Light;
//     src: url("../static/fonts/FiraCode-Light.ttf");
// }

// @font-face {
//     font-family: FiraCode-Medium;
//     src: url("../static/fonts/FiraCode-Medium.ttf");
// }

// @font-face {
//     font-family: FiraCode-Retina;
//     src: url("../static/fonts/FiraCode-Retina.ttf");
// }

@font-face {
    font-family: FiraCode;
    src: url("../static/fonts/FiraCode-VariableFont_wght.ttf");
}

@font-face {
    font-family: NotoColorEmoji;
    src: url("../static/fonts/NotoColorEmoji-Regular.ttf");
}
